import React from "react"
import styles from "./footer.module.scss"

const Footer = () => (
  <footer className={styles.container}>
    <div className={styles.content}>
      © {new Date().getFullYear()},{" "}
      <a target="_blank" href="https://gennady.pp.ua/">
        Gennady Dogaev
      </a>
    </div>
  </footer>
)

export default Footer
