import { useEffect, useRef } from "react";

/**
 * @return {React.MutableRefObject<boolean>}
 */
const useIsMounted = () => {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export default useIsMounted;
