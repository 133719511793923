import React, { useState, useCallback } from "react"
import styles from "./mobile-menu.module.scss"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import useIsMounted from "../hooks/useIsMounted"

const MobileMenu = () => {
  const [visible, setVisible] = useState(false)
  const [menuClassName, setMenuClassName] = useState("")
  const isMounted = useIsMounted()

  const openMenu = useCallback(e => {
    e.nativeEvent.stopImmediatePropagation()
    setMenuClassName("")
    setVisible(true)
  }, [])

  const closeMenu = useCallback(() => {
    setMenuClassName(styles.menuWillDisappear)
    setTimeout(() => {
      if (isMounted.current) {
        setVisible(false)
      }
    }, 500)
  }, [])

  return (
    <>
      <a onClick={openMenu} className={styles.openButton}>
        <FontAwesomeIcon icon={faBars} />
      </a>
      {visible && (
        <>
          <div className={styles.overlay} onClick={closeMenu} />
          <div className={`${styles.menu} ${menuClassName}`}>
            <Link
              to={"/"}
              className={styles.item}
              activeClassName={styles.current}
            >
              Home
            </Link>
            <Link
              to={"/privacy-policy/"}
              className={styles.item}
              activeClassName={styles.current}
            >
              Privacy Policy
            </Link>
            <Link
              to={"/tos/"}
              className={styles.item}
              activeClassName={styles.current}
            >
              Terms &amp; Conditions
            </Link>
          </div>
        </>
      )}
    </>
  )
}

export default MobileMenu
