import { Link } from "gatsby"
import React from "react"
import styles from "./menu.module.scss"

export const Menu = () => (
  <div className={styles.menu}>
    <Link
      to={"/"}
      className={styles.item}
      activeClassName={styles.current}
    >
      Home
    </Link>
    <Link
      to={"/privacy-policy/"}
      className={styles.item}
      activeClassName={styles.current}
    >
      Privacy Policy
    </Link>
    <Link
      to={"/tos/"}
      className={styles.item}
      activeClassName={styles.current}
    >
      Terms &amp; Conditions
    </Link>
  </div>
)

export default Menu
